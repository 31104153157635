// 工时统计视图详情
<template>

    <div class="hoursViewDetails baseBg">
        <backUp to="/taskManage/myTask?toStatistical=true"></backUp>
        <div class="timeBox">
            <p class="time">{{day}}</p>
            <p class="info"
               v-if="leaveHours">调休：
                <span v-for="(item,idx) in leaveTimeRanges"
                      class="mr15"
                      :key="idx">{{item[0]}} ~ {{item[1]}}</span>
                （{{leaveHours}}h）
            </p>
        </div>
        <div class="listBox">
            <el-table :data="tableData.data"
                      :height="tableHeight"
                      border
                      stripe>
                <el-table-column v-for="col in tableData.columns"
                                 :prop="col.id"
                                 :key="col.id"
                                 :label="col.label"
                                 align="center"
                                 :min-width="col.width">
                </el-table-column>
                <el-table-column prop="id"
                                 label="操作"
                                 align="center"
                                 min-width="100">
                    <template slot-scope="scope">
                        <!-- 表格操作组件 -->
                        <com-table-caozuo :item="scope.row"
                                          :detailRole="detailRole"
                                          :showBtn="['TaskDetails', 'Del']"
                                          @update="update"></com-table-caozuo>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>

</template>

<script>
import ComTableCaozuo from "./components/comTableCaozuo";
import { Loading } from "element-ui";
import API from "@/api/taskManage.js";
import backUp from "@/components/backUp";
export default {
    name: "hoursViewDetails",

    props: [],

    components: {
        ComTableCaozuo,
        backUp,
    },

    data() {
        return {
            day: this.$route.query.day,

            leaveStart: "", // 调休开始时间
            leaveEnd: "", // 调休结束时间
            leaveTimeRanges: [], // 调休区间
            leaveHours: "", // 调休
            tableData: {
                data: [],
                columns: [
                    { id: "name", label: "任务名称", width: "100" },
                    { id: "dayHours", label: "本日工时", width: "100" },
                    { id: "wadHours", label: "补填工时", width: "100" },
                    { id: "projectName", label: "关联项目", width: "100" },
                    { id: "estimateHours", label: "预计工时", width: "100" },
                    { id: "currentHours", label: "实际工时", width: "100" },
                    { id: "isFinish", label: "是否完成", width: "100" },
                    { id: "startTimeFormat", label: "开始时间", width: "100" },
                    { id: "endTimeFormat", label: "截止时间", width: "100" },
                ],
            },
            detailRole: null, // 权限数据
        };
    },

    created() {},

    mounted() {
        // 更新数据
        this.update();
    },

    methods: {
        // 更新数据
        update() {
            this.getList();
        },
        // 获取列表
        getList() {
            let loading = Loading.service({
                target: document.querySelector(".reserveProjectDetails"),
            });
            API.statisticalHourTodayView(this.day)
                .then((res) => {
                    loading.close();
                    this.leaveTimeRanges = res.content.leaveTimeRanges || [];
                    this.leaveHours = res.content.leaveHours; // 调休
                    let tableData = res.content.task;
                    tableData.forEach((item) => {
                        item.isFinish = item.status ? "完成" : "未完成";
                    });
                    this.tableData.data = res.content.task;
                    this.pageTotal = res.content.total;
                })
                .catch(() => {
                    loading.close();
                });
        },
    },

    computed: {
        // 权限数据
        roleInfo() {
            return this.$store.state.role.roleInfo;
        },
        tableHeight() {
            return this.commonJs.getTableHeight({ pageOtherHeight: 200 });
        },
    },

    watch: {
        //角色按钮权限
        roleInfo: {
            deep: true,
            immediate: true,
            handler: function (val) {
                this.detailRole = this.commonJs.tabIsAlive(
                    val,
                    "统计信息_工时统计视图当日"
                );
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.timeBox {
    overflow: hidden;
    background: #fff;
    margin-bottom: 15px;
    border-radius: 8px;
    padding-left: 15px;
}
.timeBox p {
    float: left;
    line-height: 60px;
    vertical-align: middle;
    margin: 0 50px 0 15px;
}
.timeBox .time {
    font-size: 20px;
}
</style>
